/* Overlay styles */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure overlay is on top */
}

.overlay-content {
    padding: 8px;
    border-radius: 10px;
    text-align: center;
    width: 80%;
    height: 70%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for the content */
}


