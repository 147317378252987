@import 'tailwindcss/tailwind.css';

@font-face {
  font-family: 'Polin-Bold';
  src: url('assets/fonts/Polin-Bold.woff') format('woff'),
       url('assets/fonts/Polin-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Polin-Light';
  src: url('assets/fonts/Polin-Light.woff') format('woff'),
  url('assets/fonts/Polin-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lia-Shamaim';
  src: url('assets/fonts/LiaShamaim-RegularWide.woff') format('woff'),
  url('assets/fonts/LiaShamaim-RegularWide.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
:root {
  --main-green-color: #485823;
  --accent-color: #00ff00; /* Green color */
  --text-color: #333; /* Dark gray text color */
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Polin-Light', Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
